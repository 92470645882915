import styled from '@emotion/styled';
import React, { useEffect, useState } from 'react';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Box, ClickAwayListener, Grow, MenuItem, MenuList, Paper, Popper, Typography } from '@mui/material';
import { Outlet, useParams } from 'react-router-dom';
import RootRoute from '../../../routes/RootRoute';
import useAuth from '../../../utils/hooks/useAuth';
import AsgardeoLogo from '../logos/AsgardeoLogo';
import InCountryLogo from '../logos/InCountryLogo';
import { Providers } from '../../../types/providers';
import OktaLogo from '../logos/OktaLogo';

interface IProps {

}

const AppLayout: React.FC<IProps> = () => {
  const params = useParams();


  const {
    isAuthenticated,
    user,
    getProvider,
    signOut,
  } = useAuth();



  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLButtonElement>(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event | React.SyntheticEvent) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === 'Escape') {
      setOpen(false);
    }
  }

  const prevOpen = React.useRef(open);

  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current!.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const onSignOutClick: React.MouseEventHandler<HTMLLIElement> = (e) => {
   signOut(window.location.origin + '/logout');
    handleClose(e);
  }

  return (
    <Wrapper>
      <Header>
        <Box display='grid' gridAutoFlow='column' gap={2} justifyContent='flex-start' alignItems='center'>
          <InCountryLogo />
          {getProvider() === Providers.asgardeo ? <AsgardeoLogo /> : <OktaLogo />}
        </Box>
        <Box>
          <Typography variant='h5' fontWeight='bold' color='white' align='center'>IAM Admin</Typography>
        </Box>
        <Box justifySelf='end' ref={anchorRef} onClick={handleToggle}>
          {isAuthenticated && (
            <Box display='grid' gridTemplateColumns='auto auto' alignItems='center' style={{ cursor: 'pointer' }}>
              <Typography color='white'>{user?.email}</Typography>
              <ArrowDropDownIcon style={{ color: 'white' }} />
              <Popper
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                placement="bottom-end"
                transition
                disablePortal
                style={{ zIndex: 1000 }}
              >
                {({ TransitionProps, placement }) => (
                  <Grow
                    {...TransitionProps}
                    style={{
                      transformOrigin:
                        placement === 'bottom-start' ? 'right top' : 'right bottom',
                    }}
                  >
                    <Paper>
                      <ClickAwayListener onClickAway={handleClose}>
                        <MenuList
                          autoFocusItem={open}
                          id="composition-menu"
                          aria-labelledby="composition-button"
                          onKeyDown={handleListKeyDown}
                        >
                          <MenuItem onClick={onSignOutClick}>Logout</MenuItem>
                        </MenuList>
                      </ClickAwayListener>
                    </Paper>
                  </Grow>
                )}
              </Popper>
            </Box>
          )}
        </Box>
      </Header>
      <Content>
        {(!params.provider || !params.tenant) ? <RootRoute /> : <Outlet />}
      </Content>
    </Wrapper>
  );
}


export default AppLayout;

const Wrapper = styled.div`
  display: grid;
  grid-template-rows: auto 1fr;
  gap: 8px;
  height: 100%;
`;

const Header = styled.header`
  display: grid;
  grid-template-columns: 300px 1fr 300px;
  align-items: center;
  justify-items: space-between;
  width: 1440px;
  margin: 0 auto;
  padding: 24px 72px;

`;

const Content = styled.div`
  width: 1440px;
  margin: 0 auto;
  padding:0px 72px;
`;
