import { Providers } from '../types/providers';

class BorderApiClient {

  public fetchUsers = async (countryCode: string, tenantId: string, token: string) => {
    const requestOptions = {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    };
    const res = await fetch(
      `https://${countryCode}-proxy-mt-01.preprod.incountry.io/x-inc-${tenantId}/scim2/Users?filter=urn:scim:wso2:schema.country Eq United States`, requestOptions
    );

    return await res?.json();
  };

  public fetchApiAccessToken = async (countryCode: string, providerName: Providers, accessToken: string, idToken: string, tenantId: string) => {
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        "provider": providerName,
        "access_token": accessToken,
        "id_token": idToken,
        "tenant_id": tenantId
      }),
    };
    const res = await fetch(
      `https://${countryCode}-proxy-mt-01.preprod.incountry.io/validate/iamadmin/${providerName}/token`, requestOptions
    );

    return await res?.json();
  };

  public fetchOktaUsers = async (search?: string) => {
    const requestOptions = {
      method: 'GET',
      headers: {
        'Authorization': `SSWS 00VO0JVvLksiU1YQeBCIXtqY0NSYOc12QC6_nI-qk5`,
      },
    };

    const res = await fetch(
      `https://okta-proxy.uat.incountry.zhaoerxing.top/api/v1/users${search ? `?q=${search}` : ''}`, requestOptions
    );

    return await res?.json();
  };
}

const borderApiClient = new BorderApiClient();

export default borderApiClient;
