import { useAuthContext } from '@asgardeo/auth-react';
import { useOktaAuth } from '@okta/okta-react';
import { Providers } from '../../types/providers';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

const useAuth = () => {
  const params = useParams();
  const [user, setUser] = useState<{ email?: string, idToken?: string, accessToken?: string }>();

  const {
    getBasicUserInfo,
    state: asgardeoState,
    signIn: asgardeoSignIn,
    signOut: asgardeoSignOut,
    getAccessToken,
    getIDToken,
  } = useAuthContext();

  const { authState: oktaState, oktaAuth } = useOktaAuth();

  const isAuthenticated = asgardeoState?.isAuthenticated || oktaState?.isAuthenticated;
  const isLoading = asgardeoState?.isLoading || !oktaState;


  useEffect(() => {
    if (!isAuthenticated) return;
    if (getProvider() == Providers.asgardeo) {
      (async (): Promise<void> => {
        const userInfo = await getBasicUserInfo();
        const idToken = await getIDToken();
        const accessToken = await getAccessToken();

        setUser({ email: userInfo?.username, idToken, accessToken })
      })();
      return;
    };
    if (getProvider() == Providers.oktaCust) {
      (async (): Promise<void> => {
        const idToken = oktaState?.idToken?.idToken;
        const accessToken = oktaState?.accessToken?.accessToken;

        setUser({ email: oktaState?.idToken?.claims?.email, idToken, accessToken })
      })();
      return;
    };

  }, [isAuthenticated, getBasicUserInfo])

  const getProvider = () => {
    if (isAuthenticated) {
      if (asgardeoState?.isAuthenticated) {
        return Providers.asgardeo;
      }
      if (oktaState?.isAuthenticated) {
        return Providers.oktaCust
      }
    } else {
      if (params.provider === Providers.asgardeo) {
        return Providers.asgardeo;
      }
      if (params.provider === Providers.oktaCust) {
        return Providers.oktaCust
      }
    }
  }


  const signIn = (state: string) => {
    const provider = getProvider();
    if (provider === Providers.asgardeo) {
      return asgardeoSignIn({ state });
    }
    if (provider === Providers.oktaCust) {
      return oktaAuth?.signInWithRedirect({ state });
    }
  }

  const signOut = (postLogoutRedirectUri?: string) => {
    const provider = getProvider();
    if (provider === Providers.asgardeo) {
      asgardeoSignOut();
    }
    if (provider === Providers.oktaCust) {
      oktaAuth?.signOut({ postLogoutRedirectUri });
    }
  }

  return { isAuthenticated, isLoading, user, getProvider, signIn, signOut, getBasicUserInfo }
}

export default useAuth;