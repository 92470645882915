import LaunchIcon from '@mui/icons-material/Launch';
import SearchIcon from '@mui/icons-material/Search';
import { InputAdornment, TextField } from '@mui/material';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { FC, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { formatDate } from '../../utils/dates';
import EnhancedTable, { IHeader, IRow } from '../common/EnhancedTable';
import { IOktaUsers } from './OktaMain';

interface IProps {
  users?: typeof IOktaUsers;
  search?: string;
  onSearchChange: any;
}

const OktaUsers: FC<IProps> = ({users, search, onSearchChange }) => {

  const renderTableHeaders = (): IHeader[] => [
    { id: 'id', align: 'left', sorting: true, label: 'ID' },
    { id: 'login', align: 'left', sorting: true, label: 'Login'},
    { id: 'email', align: 'left', sorting: true, label: 'Email', },
    { id: 'name', align: 'left', sorting: true, label: 'Name', },
    { id: 'status', align: 'left', sorting: true, label: 'Status', },
    { id: 'created', align: 'left', sorting: true, label: 'Created', },
    { id: 'activated', align: 'left', sorting: true, label: 'Activated', },
    { id: 'actions', align: 'center', sorting: false, label: 'OKTA console', },
  ];

  const renderTableRows = (): IRow[] => {
    return (users || [])?.map((u, uIdx) => {
      return {
        id: uIdx,
        payload: u,
        fields: [
          { id: uuidv4(), data: u?.id, align: 'left' },
          { id: uuidv4(), data: u?.profile.login, align: 'left'},
          { id: uuidv4(), data: u?.profile.email, align: 'left' },
          { id: uuidv4(), data: u?.profile?.firstName + ' ' + u?.profile?.lastName, align: 'left' },
          { id: uuidv4(), data: u?.status, align: 'left' },
          { id: uuidv4(), data: u?.created, align: 'left' },
          { id: uuidv4(), data: u?.activated || '', align: 'left' },
          { id: uuidv4(), data: <IconButton onClick={() => {window.open(`https://incountry-poc-admin.oktapreview.com/admin/user/profile/view/${u?.id}`)}}><LaunchIcon /></IconButton>, align: 'center' },
        ],
      };
    });
  };

  const renderToolbar = () => {
    const endAdornment = (
      <InputAdornment position='end'>
        <IconButton edge='end'>
          <SearchIcon />
        </IconButton>
      </InputAdornment>
    )

    return (
      <Toolbar>
        <Box py={2} width='100%' display='grid' gridTemplateColumns='1fr 300px' alignItems='center'>
          <Box>
            <Typography
              variant="h4"
              id="tableTitle"
              component="div"
            >
              Users
              <Typography variant='caption'> (Total: {users?.length})</Typography>
            </Typography>
          </Box>
          <TextField variant='outlined' value={search} onChange={(evt) => onSearchChange(evt.target.value)} InputProps={{ endAdornment }} />
          <div />
        </Box>
      </Toolbar>
    );
  }

  return (
      <Paper sx={{ width: '100%', mb: 2, overflow: 'hidden', height: '96%' }}>
        {renderToolbar()}
        <EnhancedTable
          sortBy='emails'
          stickyHeader
          headers={renderTableHeaders()}
          rows={renderTableRows()}
          tableLayout='fixed'
        />
      </Paper>
  );
}

export default OktaUsers;