
import { FC, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import OktaAuth from '@okta/okta-auth-js';

interface IProps {
  oktaAuth: OktaAuth;
}

const CustomOktaCallback: FC<IProps> = ({ oktaAuth }) => {
  const navigate = useNavigate();

  useEffect(() => {
    const handleCallback = async () => {
      try {
        const { tokens, state } = await oktaAuth.token.parseFromUrl();
        oktaAuth.tokenManager.setTokens(tokens);
        console.log(state)
        navigate(state);
      } catch (error) {
        console.error(error)
      }
    };

    handleCallback();
  }, [navigate]);

  return <div>Loading...</div>;
};

export default CustomOktaCallback;