import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  createBrowserRouter,
  Navigate,
  RouterProvider
} from "react-router-dom";
import { AuthProvider as AsgardeoAuthProvider } from '@asgardeo/auth-react';
import { ThemeProvider } from '@mui/material/styles';
import AppLayout from './components/common/layouts/AppLayout';
import RootErrorBoundary from './components/common/RootErrorBoundary';
import AppRoute from './routes/AppRoute';
import LogoutRoute from './routes/LogoutRoute';
import { getAsgardeoConfig } from './utils/providerConfigs/asgardeo-config';
import { theme } from './utils/theme/mui';
import OktaAuth from '@okta/okta-auth-js';
import { Security } from '@okta/okta-react';
import CustomOktaCallback from './components/oktaCust/CustomOktaCallback';
import config from './utils/providerConfigs/okta-config';

import './styles/global.css';
console.log('OKTA config', config)
const oktaAuth = new OktaAuth(config.oidc);


const router = createBrowserRouter([
  {
    index: true,
    element: <Navigate replace to='/dashboard' />,
  },
  {
    path: "/",
    errorElement: <RootErrorBoundary />,
    children: [
      {
        path: "/login/callback",
        element: <CustomOktaCallback oktaAuth={oktaAuth} />,
      },
      {
        path: '/dashboard',
        element: <AppLayout />,
        children: [
          {
            path: "/dashboard/:countryCode/:provider/:tenant",
            element: <AppRoute />,
          },
        ]
      },
      {
        path: "/logout",
        element: <LogoutRoute />,
      },
    ],
  },
]);


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);


root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <AsgardeoAuthProvider config={getAsgardeoConfig()}>
        <Security oktaAuth={oktaAuth} restoreOriginalUri={(_oktaAuth, originalUri) => {}}>
          <RouterProvider router={router} />
        </Security>
      </AsgardeoAuthProvider>
    </ThemeProvider>
  </React.StrictMode>
);