import { FC } from "react";
import PaperLayout from '../components/common/layouts/PaperLayout';
import { Box, Typography, Button } from '@mui/material';
import { error } from 'console';

const LogoutRoute: FC = () => {
  return (
    <PaperLayout>
      <Box height='100%' alignContent='center'>
        <Typography variant='h4' mb={2} align='center'>You sucessfully logged out</Typography>
      </Box>
    </PaperLayout>
  );
};

export default LogoutRoute;