
import logo from './Okta_Wordmark_White_L.png';

const OktaLogo = () =>  (
    <img
    src={logo}
    height={60}
    alt="okta"
  />
)

export default OktaLogo;