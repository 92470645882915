
// eslint-disable-next-line
export default {
  oidc: {
    clientId: '0oajg5no7zIImrQxy1d7',
    issuer: 'https://incountry-poc.oktapreview.com/',
    redirectUri: window.location.origin + '/login/callback',
    scopes: ['openid', 'profile', 'email'],
  },
  resourceServer: {
    messagesUrl: window.location.origin + '/api/messages',
  },
};
